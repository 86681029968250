// make font size smaller
$pt-grid-size: 9px;

@import '@blueprintjs/core/lib/css/blueprint';
@import '@blueprintjs/datetime/lib/css/blueprint-datetime';
@import '@blueprintjs/datetime2/lib/css/blueprint-datetime2';
@import '@blueprintjs/icons/lib/css/blueprint-icons';
@import '@blueprintjs/select/lib/css/blueprint-select';
@import '@blueprintjs/table/lib/css/table';

@import '../../node_modules/tailwindcss/dist/base';
@import '../../node_modules/tailwindcss/dist/components';
@import '../../node_modules/tailwindcss/dist/utilities';

// this uses @import instead of @import, because @import only imports files once; find another way, since @import is being deprecated
@import '@blueprintjs/colors/lib/scss/colors';

$primary-color: #137cbd;

.dark-gray1    { color:            $dark-gray1; }
.bg-dark-gray1 { background-color: $dark-gray1; }
.dark-gray2    { color:            $dark-gray2; }
.bg-dark-gray2 { background-color: $dark-gray2; }
.dark-gray3    { color:            $dark-gray3; }
.bg-dark-gray3 { background-color: $dark-gray3; }
.dark-gray4    { color:            $dark-gray4; }
.bg-dark-gray4 { background-color: $dark-gray4; }
.dark-gray5    { color:            $dark-gray5; }
.bg-dark-gray5 { background-color: $dark-gray5; }

.dark-gray5-lighter    { color:            lighten($dark-gray5, 20%); }
.bg-dark-gray5-lighter { background-color: lighten($dark-gray5, 20%); }

.bg-gray-350 {
	background-color: #dce3ea;
}

.bp5-navbar.auto {
	height: auto;
	
	.bp5-navbar-group {
		height: auto;
		flex-wrap: wrap;
		padding: 0.6rem 0rem;
	}
}

.vibrant {
	.bp5-input:disabled, .bp5-input.bp5-disabled {
		color: rgba(92, 112, 128, 0.8);
	}
}

html {
	background-color: #eeeeee;
}
body {
	box-sizing: border-box;
	height: 100vh;
	font-family: Roboto;
}

* {
	user-select: none;
}
input {
	user-select: auto;
}
.text-selectable {
	user-select: text;
}

.bp5-alert {
	max-width: 450px;
}

.bp5-input-group.text-right {
	&> .bp5-input {
		@extend .text-right;
	}
}
.bp5-input-group .bp5-input {
	font-size: 12px;
}
.bp5-input-group .bp5-input::placeholder {
	color: #bbbbbb;
}

.bp5-control.no-margin {
	margin: 0px;
}

.multiselect-button-wrapper .bp5-button .bp5-button-text {
	flex: 1;
}

.main-wrapper {
	height: 100%;
}
	.main-content {
		/*padding: 16px;*/
	}

.text-with-tooltip {
	white-space: nowrap;
	
	&.wrap {
		white-space: normal;
	}
}

.main-dialog {
	width: 95vw;
	height: 94vh;
	
	padding-bottom: 0px;
}
.invoice-dialog {
	min-width: 800px;
}

.select-person-dialog {
	min-width: 620px;
	
	.bp5-dialog-body {
		padding-left: 0.8rem;
		padding-right: 0.8rem;
	}
	.select-person--age {
		min-width: 105px;
	}
}

.select-customer-dialog {
	min-width: 680px;
}
.add-edit-country-dialog, .add-edit-measuring-unit-dialog {
	min-width: 420px;
}
.edit-activity-item-dialog {
	min-width: 640px;
	
	&.wide {
		min-width: 880px;
	}
	
	.activity-item-dialog-documents {
		height: 390px;
	}
}

.select-customer-dialog {
	.select-customer-dialog--note {
		.bp5-form-content {
			flex: 1;
			padding: 0 0 13.5px;
			
			textarea.bp5-input {
				height: 100%;
			}
		}
	}
}

.edit-settings-item-dialog.color {
	width: 1028px;
	max-width: 90%;
}
.edit-settings-item-dialog.multiline {
	min-height: 300px;
	width: 800px;
	max-width: 90%;
	
	.edit-settings-item-dialog-multiline-value {
		display: flex;
		flex-direction: column;
		flex: 1;
		
		.bp5-form-content {
			display: flex;
			flex-direction: column;
			flex: 1;
			
			textarea { flex: 1; }
		}
	}
}
.edit-settings-item-dialog.document {
	width: 1100px;
	max-width: 90%;
	
	.edit-settings-item-dialog-document-value {
		textarea {
			min-height: 200px;
		}
	}
}

.add-edit-user-dialog, .add-edit-user-dsc-dialog {
	width: 420px;
	
	&.wide {
		width: 930px;
	}
	
	.user--work-types--line {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 30px;
		grid-gap: 4px;
		grid-auto-rows: minmax(12px, auto);
		
		padding-bottom: 4px;
	}
	.user--daily-logbook-activity--line {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 30px;
		grid-gap: 4px;
		grid-auto-rows: minmax(12px, auto);
		
		padding-bottom: 4px;
	}
}
.add-edit-user-dsc-dialog {
	width: 520px;
	
	&.wide {
		width: 1000px;
	}
	.add-edit-user-dsc-dialog-right-side {
		width: 670px;
	}
}

textarea.bp5-input.table-full-h {
	height: 100%;
}

.suggestion-items {
	padding: 0px;
	list-style-type: none;
}
.suggestion-item {
	/*transition: background-color 300ms;*/
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	cursor: pointer;
}
	.suggestion-item:hover {
		/*background-color: #ff0000;*/
	}

.from-until.bp5-input-group .bp5-input {
	width: 120px;
}
/*.react-datepicker__input-container input {
	width: 86px;
	text-align: center;
}*/

.text-with-tooltip {
	.bp5-icon {
		vertical-align: baseline;
		opacity: 0.4;
		transition: opacity 300ms ease;
		
		&:hover {
			opacity: 1.0;
		}
	}
}

.date-picker-dialog {
	.bp5-datepicker-footer {
		.bp5-button {
			background-color: $primary-color;
			color: #ffffff;
			
			&:last-child {
				display: none;
			}
		}
	}
}

.bp5-table-column-name {
	font-weight: 600;
}

.bp5-popover.limit-height {
	max-height: 360px;
	overflow-y: auto;
}

.reservation-additional-info-tabs {
	.bp5-tab {
		line-height: normal;
		
		&[aria-selected="true"] {
			
		}
	}
	
	.bp5-tab-indicator-wrapper {
		display: none;
	}
	
	.bp5-tab-panel {
		margin-top: 0px;
	}
}

.reservation-services {
	background-color: #e0e8ec;
}

.date-picker-dialog {
	&.bp5-dialog {
		width: 280px;
	}
	
	.bp5-datepicker {
		background-color: transparent;
	}
	
	.bp5-dialog-footer-actions {
		justify-content: center;
	}
}
.date-range-picker-dialog {
	&.bp5-dialog {
		width: 480px;
	}
	
	.bp5-daterangepicker {
		.DayPicker-Day--selected-range {
			background-color: rgba(19, 124, 189, 0.3);
		}
	}
	
	.bp5-datepicker {
		background-color: transparent;
	}
}

.dialog--available-accommodations {
	&.bp5-dialog {
		width: 720px;
	}
}

.cell-button {
	line-height: 20px;
	margin-bottom: 3px;
}

.accommodation-nav {
	width: 250px;
	padding-right: 0.5rem;
	
	.item {
		padding: 0.5rem;
		
		cursor: pointer;
		color: #ffffff;
		background-color: #394b59;
		transition: background-color 300ms ease, border 300ms ease;
		
		border-bottom: 1px solid #617f97;
		border-left: 3px solid transparent;
		
		&.item-active {
			border-left: 3px solid #d9822b;
		}
		
		&:hover {
			background-color: scale-color(#394b59, $lightness: 10%);
		}
		
		.item-open {
			padding: 0.5rem;
			
			background-color: rgba(0, 0, 0, 0);
			transition: background-color 300ms ease;
			
			&.active {
				&:hover {
					background-color: rgba(0, 0, 0, 0.2);
				}
			}
		}
		
		.room-dirty {
			background-color: rgba(0, 0, 0, 0);
			transition: opacity 300ms ease, background-color 300ms ease;
			cursor: pointer;
			padding: 0.5rem;
			
			&:hover {
				background-color: rgba(0, 0, 0, 0.2);
			}
			
			&.active {
				svg { opacity: 1; }
			}
			
			svg {
				opacity: 0.5;
			}
		}
	}
	
	.subitem {
		padding: 1rem;
		
		cursor: pointer;
		color: #ffffff;
		background-color: #617f97;
		transition: background-color 300ms ease, border 300ms ease;
		
		border-left: 3px solid transparent;
		
		&.item-active {
			border-left: 3px solid #d9822b;
		}
		
		&:hover {
			background-color: scale-color(#617f97, $lightness: 10%);
		}
	}
}

.activity-reservation-clipboard-card {
	position: absolute;
	right: 8px;
	top: 8px;
	background-color: #F29D49;
	z-index: 10;
}

.timeline {
	.room-dirty-background {
		&.dirty {
			fill: rgb(255, 170, 170);
		}
		&.clean {
			fill: #00ff00;
		}
		&.waiting {
			fill: none;
		}
	}
	.room-hover-background {
		fill: #000000;
		opacity: 0.2;
	}
	
	.room-dirty-indicator {
		cursor: pointer;
		
		.room-dirty-indicator--background {
			fill: #000000;
			opacity: 0.1;
			transition: opacity 100ms ease;
		}
		.room-dirty-indicator--icon {
			path {
				fill: #000000;
				opacity: 0.3;
				transition: opacity 100ms ease;
			}
		}
		
		&.dirty, &.clean {
			.room-dirty-indicator--icon {
				path {
					fill: #ffffff;
					opacity: 1;
				}
			}
		}
		
		&:hover {
			.room-dirty-indicator--background {
				opacity: 0.2;
			}
		}
	}
	
	.reservation-request-bar {
		cursor: pointer;
		
		.reservation-bar-background-pattern {
			opacity: 0;
		}
		
		.reservation-bar-background {
			fill: #ffffff;
			stroke: #000000;
			stroke-width: 2px;
			stroke-dasharray: 4px;
		}
		text { fill: #000000; }
		
		&.confirmed {
			.reservation-bar-background { fill: #e06e00; }
		}
	}
	.reservation-bar {
		cursor: pointer;
		
		.reservation-bar-background-pattern {
			opacity: 0;
		}
		
		&.status-new {
			.reservation-bar-background { fill: #e0e000; }
			text { fill: #000000; }
		}
		&.status-waiting-for-confirmation, &.status-offer-sent {
			.reservation-bar-background { fill: #e06e00; }
		}
		&.status-offer-sent {
			.reservation-bar-background-pattern { opacity: 1; }
		}
		&.status-confirmed, &.status-advance-invoice-sent {
			.reservation-bar-background { fill: #2bb71c; }
		}
		&.status-advance-invoice-sent {
			.reservation-bar-background-pattern { opacity: 1; }
		}
		&.status-closed {
			.reservation-bar-background { fill: #aaaaaa; }
		}
		&.status-dragging {
			.reservation-bar-background { fill: rgba(57, 75, 89, 0.8); }
		}
		&.status-deleting {
			.reservation-bar-background { fill: #ff0000; }
		}
		&.status-reversed {
			.reservation-bar-background { fill: #ff0000; }
		}
		&.status-no-show {
			.reservation-bar-background { fill: #ff0000; }
		}
		&.status-not-for-rent {
			.reservation-bar-background { fill: #ff0000; }
			.reservation-bar-background-pattern { opacity: 1; }
		}
		&.status-invalid {
			.reservation-bar-background { fill: #cc0000; }
		}
		
		&.custom-status-1 {
			.custom-status-indicator { fill: #ff0000; }
		}
		&.custom-status-2 {
			.custom-status-indicator { fill: #0000ff; }
		}
		&.custom-status-3 {
			.custom-status-indicator { fill: #00ffff; }
		}
		&.custom-status-4 {
			.custom-status-indicator { fill: #ff00ff; }
		}
		&.custom-status-5 {
			.custom-status-indicator { fill: #0000ff; }
		}
		&.custom-status-6 {
			.custom-status-indicator { fill: #00ffff; }
		}
		&.custom-status-7 {
			.custom-status-indicator { fill: #ff00ff; }
		}
		
		text {
			fill: #ffffff;
		}
		
		.reservation-bar-background {
			opacity: 1;
			transition: opacity 300ms ease;
		}
		
		&:hover, &.hover {
			.reservation-bar-background {
				opacity: 0.8;
			}
		}
		
		&.dragging {
			/*filter: url(#reservation-bar-dragging);*/
			
			.reservation-bar-background {
				opacity: 0.8;
			}
		}
		
		&.guests-checked-in {
			.reservation-bar-background {
				stroke: #000000;
				stroke-width: 2px;
			}
		}
	}
}

@keyframes timeline-tooltip--fade-in-keyframes {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@keyframes timeline-tooltip--fade-out-keyframes {
	from { opacity: 1; }
	to   { opacity: 0; }
}
.timeline-tooltip {
	&.timeline-tooltip-visible {
		animation: timeline-tooltip--fade-in-keyframes 200ms;
	}
	&.timeline-tooltip-hidden {
		animation: timeline-tooltip--fade-out-keyframes 200ms;
	}
	&.timeline-tooltip-gone {
		display: none;
	}
	
	.timeline-tooltip-contents {
		padding: 16px;
		padding-top: 0px;
		
		.timeline-tooltip-name {
			font-weight: 600;
			color: #ffffff;
			text-align: center;
			padding-bottom: 0.5rem;
		}
		.timeline-tooltip-title {
			font-weight: 600;
			color: #bbbbbb;
		}
		.timeline-tooltip-value {
			padding-bottom: 0.5rem;
			color: #ffffff;
		}
		.timeline-tooltip-note {
			padding-bottom: 0.5rem;
			color: #ffffff;
			max-height: 50px;
			overflow: hidden;
		}
	}
}

$id-column-color: #bbbbbb;

.reservation-guests-table {
	display: grid;
	grid-template-columns: 27px 98px 1fr 1fr 120px 140px 120px 120px 160px;
	grid-gap: 0px;
	grid-auto-rows: minmax(28px, auto);
	
	.reservation-guests-table-header-col, .reservation-guests-table-col {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		border-bottom: 1px solid #ccd4d8;
		white-space: nowrap;
		overflow: hidden;
		font-size: 0.7rem;
		line-height: 28px;
		
		.bp5-button {
			font-size: 0.7rem;
		}
		
		.bp5-control {
			margin: 0px;
		}
	}
	
	.reservation-guests-table-header-col {
		grid-row: 1;
		font-weight: 600;
		background-color: #e0e8ec;
	}
	
	.reservation-guests-table-col {
		background-color: #ffffff;
		
		&.col-1 {
			color: $id-column-color;
			text-align: center;
		}
		&.col-7, &.col-8 {
			text-align: center;
		}
	}
}

.reservations-report--occupancy_income--table {
	width: 100%;
	max-width: 1300px;
	user-select: text;
	
	display: grid;
	grid-template-columns:
		1fr   /* title                    */
		40px  /* spacer                   */
		90px  /* occupancy - days         */
		80px  /* occupancy - percent      */
		240px /* occupancy - progress bar */
		40px  /* spacer                   */
		90px  /* income - value           */
		80px  /* income - percent         */
		240px /* income - progress bar    */;
	grid-gap: 0px;
	
	&> * {
		user-select: text;
	}
}

.reservations-report--arrivals--table {
	width: 100%;
	max-width: 700px;
	user-select: text;
	
	display: grid;
	grid-template-columns:
		1fr   /* title              */
		180px /* country            */
		80px  /* reservation count  */
		80px  /* reservation length */;
	grid-gap: 0px;
	
	&> * {
		user-select: text;
	}
}

.reservations-report--arrivals--countries--table {
	width: 100%;
	max-width: 700px;
	user-select: text;
	
	display: grid;
	grid-template-columns:
		1fr   /* title              */
		180px /* country            */
		80px  /* reservation count  */
		80px  /* reservation length */;
	grid-gap: 0px;
	
	&> * {
		user-select: text;
	}
}

.activities-guests-table {
	display: grid;
	position: relative;
	grid-template-columns:
		80px  /* actions     */
		60px  /* guest id    */
		1fr   /* title       */
		110px /* phone       */
		170px /* email       */
		60px  /* guest count */
		2fr   /* note        */
		50px  /* mode        */
		100px /* country     */
		195px /* invoice     */;
	grid-gap: 0px;
	grid-auto-rows: minmax(32px, auto);
	
	&.narrow {
		grid-template-columns:
			80px  /* actions     */
			1fr   /* title       */
			60px  /* guest count */
			0.6fr   /* note        */;
	}
	&.one-column {
		grid-template-columns: 1fr;
	}
	&.compact {
		grid-auto-rows: minmax(25px, auto);
		
		.activities-guests-table-header-col, .activities-guests-table-col {
			line-height: 25px;
			
			.bp5-button {
				margin-bottom: 0px;
				
				&.print-pos {
					line-height: 1rem;
				}
			}
		}
	}
	
	&.activities-guests-table-header {
		position: sticky;
		top: 0px;
		z-index: 19;
	}
	
	background-color: #ffffff;
	color: #000000;
	
	&.status-new {
		background-color: #e0e000;
	}
	&.status-waiting-for-confirmation, &.status-offer-sent {
		background-color: #e06e00;
		color: #ffffff;
	}
	&.status-confirmed, &.status-advance-invoice-sent {
		background-color: #2bb71c;
		color: #ffffff;
	}
	&.status-advance-invoice-sent { }
	&.status-closed {
		background-color: #aaaaaa;
		color: #ffffff;
	}
	&.status-dragging {
		background-color: rgba(57, 75, 89, 0.8);
		color: #ffffff;
	}
	&.status-deleting {
		background-color: #ff0000;
		color: #ffffff;
	}
	&.status-reversed {
		background-color: #ff0000;
		color: #ffffff;
	}
	&.status-no-show {
		background-color: #ff0000;
		color: #ffffff;
	}
	&.status-invalid {
		background-color: #cc0000;
		color: #ffffff;
	}
	&.pattern-diagonal-stripes {
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18'><defs><pattern id='pattern-diagonal-stripes' viewBox='0,0,40,40' width='18' height='18' patternUnits='userSpaceOnUse'><g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.25'><g fill='%23000000'><polygon points='0 40 40 0 20 0 0 20'></polygon><polygon points='40 40 40 20 20 40'></polygon></g></g></pattern></defs><rect x='0' y='0' width='18' height='18' fill='url(%23pattern-diagonal-stripes)' opacity='0.3' /></svg>");
		background-repeat: repeat;
	}
	&.pattern-polka-dots {
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18'><defs><pattern id='pattern-polka-dots' viewBox='0,0,20,20' width='18' height='18' patternUnits='userSpaceOnUse'><g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.25'><g fill='%23000000'><circle cx='3' cy='3' r='3'></circle><circle cx='13' cy='13' r='3'></circle></g></g></pattern></defs><rect x='0' y='0' width='18' height='18' fill='url(%23pattern-polka-dots)' opacity='0.3' /></svg>");
		background-repeat: repeat;
	}
	
	.custom-status {
		display: none;
		position: absolute;
		left: 1px;
		top: 0px;
	}
	&.custom-status-1 .custom-status {
		display: block;
		
		path { fill: #ff0000; }
	}
	&.custom-status-2 .custom-status {
		display: block;
		
		path { fill: #0000ff; }
	}
	&.custom-status-3 .custom-status {
		display: block;
		
		path { fill: #00ffff; }
	}
	&.custom-status-4 .custom-status {
		display: block;
		
		path { fill: #ff00ff; }
	}
	&.custom-status-5 .custom-status {
		display: block;
		
		path { fill: #0000ff; }
	}
	&.custom-status-6 .custom-status {
		display: block;
		
		path { fill: #00ffff; }
	}
	&.custom-status-7 .custom-status {
		display: block;
		
		path { fill: #ff00ff; }
	}
	
	.activities-guests-table-header-col, .activities-guests-table-col {
		padding-left: 0.77rem;
		padding-right: 0.5rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.3);
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		font-size: 0.7rem;
		line-height: 32px;
		
		&.hide-right-border {
			border-right: 0px;
		}
		
		&:last-child {
			border-right: 1px solid rgba(0, 0, 0, 0.3);
		}
		
		.bp5-button {
			font-size: 0.7rem;
			line-height: 1.4rem;
			margin-bottom: 0.25rem;
			
			.bp5-icon {
				color: #000000;
			}
		}
		
		&.col-1 {
			border-left: 1px solid rgba(0, 0, 0, 0.3);
		}
	}
	
	.activities-guests-table-header-col {
		grid-row: 1;
		font-weight: 600;
		background-color: #394b59;
		color: #ffffff;
	}
	
	.activities-guests-table-col {
		&.activities-guests-table-time-section {
			border: 0px;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			font-weight: 700;
			background-color: #617f97;
			color: #ffffff;
			font-size: 0.8rem;
		}
		&.activities-guests-table-activity-section {
			font-weight: 700;
			font-size: 0.8rem;
			border-top: 1px solid rgba(0, 0, 0, 0.3);
			
			&.warning {
				//background-color: #E53935;
				color: #F44336;
			}
		}
		&.activities-guests-table-guest {
			
			&.guest-count, &.note {
				cursor: pointer;
				background-color: rgba(0, 0, 0, 0);
				transition: background-color 300ms ease;
				
				&:hover {
					background-color: rgba(0, 0, 0, 0.2);
				}
			}
		}
	}
	
	.activities-guests-table-col-spacer {
	}
}

.activities-guests-table-guests {
	padding-bottom: 1rem;
}

.documents-list {
	border-left: 1px solid #ccd4d8;
	border-top: 1px solid #ccd4d8;
	
	.documents-table {
		display: grid;
		grid-gap: 0px;
		grid-auto-rows: minmax(28px, auto);
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		
		&.floating-header {
			position: sticky;
			top: 0px;
		}
		
		&.hide-customer {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		
		.add-document-button {
			position: absolute;
			left: 4px;
			top: 3px;
		}
		
		.documents-table-header-col, .documents-table-col {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			border-right: 1px solid #ccd4d8;
			border-bottom: 1px solid #ccd4d8;
			white-space: nowrap;
			overflow: hidden;
			font-size: 0.7rem;
			line-height: 28px;
			
			.bp5-button {
				font-size: 0.7rem;
			}
			
			.bp5-control {
				margin: 0px;
			}
		}
		
		.documents-table-header-col {
			grid-row: 1;
			font-weight: 600;
			background-color: #e0e8ec;
			
			&.col-1 {
				padding-left: 32px;
			}
		}
		
		.documents-table-col {
			background-color: #ffffff;
		}
	}
}

.advance-invoice-list-dialog {
	min-width: 800px;
	
	.advance-invoice-list {
		border-left: 1px solid #ccd4d8;
		border-top: 1px solid #ccd4d8;
	}
	
	.advance-invoice-list-table {
		display: grid;
		grid-gap: 0px;
		grid-auto-rows: minmax(28px, auto);
		grid-template-columns: 80px 100px 100px 1fr 120px 120px 58px;
		
		&.floating-header {
			position: sticky;
			top: 0px;
		}
		
		.add-document-button {
			position: absolute;
			left: 4px;
			top: 3px;
		}
		
		.advance-invoice-list-table-header-col, .advance-invoice-list-table-col {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			border-right: 1px solid #ccd4d8;
			border-bottom: 1px solid #ccd4d8;
			white-space: nowrap;
			overflow: hidden;
			font-size: 0.7rem;
			line-height: 28px;
			
			.bp5-button {
				font-size: 0.7rem;
			}
			
			.bp5-control {
				margin: 0px;
			}
		}
		
		.advance-invoice-list-table-header-col {
			grid-row: 1;
			font-weight: 600;
			background-color: #e0e8ec;
		}
		
		.advance-invoice-list-table-col {
			background-color: #ffffff;
		}
	}
}

.dashboard {
	.dashboard-grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr;
		grid-template-areas:
			'area-1 area-2 area-3'
			'area-4 area-5 area-6';
		grid-gap: 2rem;
		padding: 2rem;
	}
	
	.dashboard-item {
		background-color: #ffffff;
		border-radius: 12px;
		
		.dashboard-item-title {
			background-color: $dark-gray5;
			color: #ffffff;
			padding: 1rem;
			border-radius: 12px 12px 0px 0px;
			text-align: center;
		}
	}
	
	.dashboard-item-1 {
		grid-area: area-1;
	}
	.dashboard-item-2 {
		grid-area: area-2;
	}
	.dashboard-item-3 {
		grid-area: area-3;
	}
	.dashboard-item-4 {
		grid-area: area-4;
	}
	
	.stats-counter {
		padding: 2rem;
	}
}

.base-list,
	.countries-list, .measuring-units-list, .payment-types-list, .post-offices-list, .warehouses-list, 
	.cash-registers-list, .invoices-list, .channels-list, .tax-rates-list, .business-premises-list, 
	.customers-list, .items-list, .market-segmentation-list, .identification-types-list, .tourist-taxes-list,
	.activities-list, .business-invoices-list, .offers-list, .guest-book-list, .reservation-list,
	.document-search-invoices-list, .document-search-reservations-list, .activity-reservation-list,
	.general-settings-list, .document-search-prepayment-invoices-list, .monthly-guest-book-report-list,
	.sales-invoice-book-items-list, .stock-inventory-list, .stock-inventory-detailed-list, .guest-tourist-taxes-list,
	.reports-items-and-services-list, .activity-daily-logbook-list, .dsc-statistics-list, .dsc-user-statistics-list,
	.dsc-users-list, .dsc-work-types-list, .dsc-daily-logbook-activities-list, .health-log-list, .reservation-requests-list,
	.users-list, .document-search-delivery-note-list {
	.results {
		border-left: 1px solid #ccd4d8;
		border-top: 1px solid #ccd4d8;
		
		&.borderless {
			border-color: transparent;
			
			.results-table {
				.results-table-header-col, .results-table-col {
					border-color: transparent;
				}
				.results-table-col {
					background-color: transparent;
				}
			}
		}
		
		.results-table {
			display: grid;
			grid-gap: 0px;
			grid-auto-rows: minmax(28px, auto);
			
			&.filters-row {
				grid-auto-rows: minmax(42px, auto);
				
				.results-table-col {
					padding-top: 6px;
				}
			}
			
			&.sticky-header {
				.filters-col {
					position: sticky;
					top: 29px;
				}
				.results-table-header-col {
					position: sticky;
					top: 0px;
				}
			}
			
			.filters-col {
				height: 42px;
				padding-top: 6px;
				z-index: 16;
			}
			
			.results-table-header-col, .results-table-col {
				padding-left: 0.5rem;
				padding-right: 0.5rem;
				border-right: 1px solid #ccd4d8;
				border-bottom: 1px solid #ccd4d8;
				white-space: nowrap;
				overflow: hidden;
				font-size: 12px;
				line-height: 28px;
				
				.bp5-button {
					font-size: 0.7rem;
					
					&.bp5-large {
						font-size: 1rem;
					}
				}
				
				.bp5-control {
					margin: 0px;
				}
				
				&.whitespace-pre {
					white-space: pre;
				}
				
				&.sum {
					background-color: transparent;
					border-right: 0px;
					border-bottom: 0px;
				}
			}
			
			&.without-vertical-lines {
				.results-table-header-col, .results-table-col {
					border-right: 0px;
				}
			}
			
			.results-table-header-col {
				z-index: 16;
				grid-row: 1;
				background-color: #e0e8ec;
				
				&.sortable {
					cursor: pointer;
					transition: background-color 300ms ease;
					
					&:hover {
						background-color: #c0d0d8;
					}
				}
			}
			
			.results-table-col {
				background-color: #ffffff;
				
				-webkit-user-select: text;
				-moz-user-select:    text;
				-ms-user-select:     text;
				user-select:         text;
				
				&.col-1 {
					/*color: $id-column-color;*/
				}
			}
			
			&.reverse-invoice, .reverse-invoice {
				color: #ff0000;
				
				.results-table-col {
					/*background-color: #ffeeee;*/
					color: #ff0000;
				}
			}
			
			&.reversed, .reversed {
				color: #ff0000;
				
				.col-invoice-amount {
					color: #ff0000;
				}
			}
			
			.multiselect-button-wrapper {
				overflow: hidden;
				flex-grow: 1;
				
				.bp5-popover-target {
					display: block;
					
					button {
						.bp5-button-text {
							width: 100%;
						}
					}
				}
			}
			
			.multiselect-button {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
	
	.results-sum {
		display: flex;
		flex-direction: row;
		
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		font-size: 12px;
		
		.col {
			flex: 1;
		}
	}
}
.large {
	.base-list,
		.countries-list, .measuring-units-list, .payment-types-list, .post-offices-list, .warehouses-list, 
		.cash-registers-list, .invoices-list, .channels-list, .tax-rates-list, .business-premises-list, 
		.customers-list, .items-list, .market-segmentation-list, .identification-types-list, .tourist-taxes-list,
		.activities-list, .business-invoices-list, .offers-list, .guest-book-list, .reservation-list,
		.document-search-invoices-list, .document-search-reservations-list, .activity-reservation-list,
		.general-settings-list, .document-search-prepayment-invoices-list, .monthly-guest-book-report-list,
		.sales-invoice-book-items-list, .stock-inventory-list, .stock-inventory-detailed-list, .guest-tourist-taxes-list,
		.reports-items-and-services-list, .activity-daily-logbook-list, .dsc-statistics-list, .dsc-user-statistics-list,
		.reservation-requests-list, .document-search-delivery-note-list {
		.results {
			.results-table {
				.results-table-header-col, .results-table-col {
					font-size: 1rem;
				}
			}
		}
	}
}

.countries-list {
	.results {
		.results-table {
			grid-template-columns: 36px 70px 110px 110px 130px 1fr 1fr;
		}
	}
}

.measuring-units-list {
	.results {
		.results-table {
			grid-template-columns: 36px 70px 1fr 1fr 80px 80px;
		}
	}
}

.cash-registers-list {
	.results {
		.results-table {
			grid-template-columns: 36px 70px 90px 1fr 1fr 1fr 1fr;
		}
	}
}

.payment-types-list {
	.results {
		.results-table {
			grid-template-columns: 36px 70px 90px 1fr 80px 110px 80px;
		}
	}
}

.warehouses-list {
	.results {
		.results-table {
			grid-template-columns: 36px 70px 1fr 120px;
		}
	}
}

.post-offices-list {
	.results {
		.results-table {
			grid-template-columns: 36px 70px 100px 1fr 1fr;
		}
	}
}

.invoices-list {
	.results {
		.results-table {
			max-height: calc(100vh - 210px);
			/*grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto;*/
			grid-template-columns: 36px 80px 70px 108px 80px 1fr 210px 126px 126px 126px 100px 120px;
		}
	}
}

.reservation-requests-list--tooltip-note {
	max-width: 400px;
}
.reservation-requests-list {
	.results {
		.results-table {
			max-height: calc(100vh - 210px);
			grid-template-columns: 40px 100px 1fr 210px 1fr 110px 126px 126px 72px 64px 180px;
			
			.quick-edit-check-in-out, .quick-edit-person-count {
				cursor: pointer;
				
				&:hover {
					text-decoration: underline;
				}
			}
			.customer-info, .person-count {
				line-height: 1.8;
				padding-top: 0.2rem;
				padding-bottom: 0.2rem;
			}
			.customer-info {
				div, span, strong, a {
					user-select: text;
				}
			}
			.note {
				padding-top: 0.9rem;
				
				.bp5-popover-target > span {
					display: block;
					text-wrap: wrap;
					line-height: normal;
					max-height: 2.7rem;
					overflow: hidden;
				}
			}
			
			.col-1 {
				border-left: 4px solid transparent;
				
				&.group-1, &.group-2 { border-left-color: #000000; }
				&.group-2 { border-left-style: dotted; }
			}
		}
	}
}
.reservation-requests-list--accommodation-selector-dialog {
	width: auto;
	
	.tree-top-node {
		margin-right: 16px;
	}
	.bp5-tree-node-content {
		height: auto;
		margin-top: 1px;
		padding-right: 0px;
		
		.bp5-tree-node-label {
			transition: opacity 300ms ease;
		}
		&:hover {
			.bp5-tree-node-label {
				opacity: 0.5;
			}
		}
	}
	.bp5-tree-node-content-0 {
		border-bottom: 1px solid black;
		height: 32px;
	}
	.bp5-tree-node-content-1 {
		padding-left: 0px;
	}
	.bp5-tree-node-caret-none {
		min-width: 0px;
	}
	
	.overflow-y-scroll {
		max-height: 80vh;
	}
}
.document-search-invoices-list {
	.results {
		.results-table {
			grid-template-columns: 108px 80px 1fr 210px 126px 120px auto;
		}
	}
}
.document-search-prepayment-invoices-list {
	.results {
		.results-table {
			grid-template-columns: 108px 80px 1fr 120px 126px 120px 120px auto;
		}
	}
}

.business-invoices-list {
	.results {
		.results-table {
			max-height: calc(100vh - 210px);
			grid-template-columns: 36px 70px 108px 80px 1fr 210px 210px 96px 56px 126px 100px 120px;
		}
	}
}

.offers-list {
	.results {
		.results-table {
			max-height: calc(100vh - 210px);
			grid-template-columns: 36px 70px 108px 80px 1fr 210px 210px 100px 120px;
		}
	}
}

.channels-list {
	.results {
		.results-table {
			grid-template-columns: 36px 70px 60px 1fr 80px 120px;
		}
	}
}

.dsc-users-list {
	.results {
		.results-table {
			grid-template-columns: 36px 70px 120px 240px 120px 70px 70px 1fr;
		}
	}
}

.users-list {
	.results {
		.results-table {
			grid-template-columns: 36px 70px 120px 240px 120px 70px 70px 1fr;
		}
	}
}

.dsc-work-types-list {
	.results {
		max-width: 800px;
		
		.results-table {
			grid-template-columns: 36px 70px 1fr 110px 160px 110px 140px 80px;
		}
	}
}

.dsc-daily-logbook-activities-list {
	.results {
		max-width: 500px;
		
		.results-table {
			grid-template-columns: 36px 70px 1fr 80px;
		}
	}
}

.identification-types-list {
	.results {
		.results-table {
			grid-template-columns: 36px 70px 60px 1fr 80px;
		}
	}
}

.tax-rates-list {
	.results {
		.results-table {
			grid-template-columns: 36px 70px 100px 1fr 120px 160px 80px;
			
			.validity-inactive {
				color: #cccccc;
			}
		}
	}
}

.items-list {
	.results {
		.results-table {
			grid-template-columns: 40px 64px 70px 140px 1fr 125px 126px 126px 100px 180px 130px;
			max-height: calc(100vh - 234px);
		}
	}
}

.market-segmentation-list {
	.results {
		.results-table {
			grid-template-columns: 36px 70px 1fr 80px;
		}
	}
}

.business-premises-list {
	.results {
		.results-table {
			grid-template-columns:
				36px /* id */
				70px /* actions */
				70px /* status */
				120px /* FURS code */
				120px /* cadastral municipality */
				120px /* building number */
				120px /* building part number */
				1fr /* street */
				80px /* house number */
				80px /* house number appendix */
				1fr /* town */
				160px /* post code */
				1fr /* post office */
				1fr /* city */
				120px /* valid from */
				120px /* registered at FURS */
				;
			
			.validity-inactive {
				color: #cccccc;
			}
		}
	}
}

.business-premises-list, .general-settings-list, .customers-list {
	.results {
		.results-table {
			.results-table-header-col, .results-table-col {
				border-right: 0px;
				
				&:last-child {
					border-right: 1px solid #ccd4d8;
				}
			}
		}
	}
}

.customers-list {
	.results {
		.results-table {
			grid-template-columns: 40px 72px 70px 65fr 35fr 140px 180px 160px 145px 80px 126px;
			max-height: calc(100vh - 234px);
		}
	}
}

.tourist-taxes-list {
	.results {
		.results-table {
			grid-template-columns: 36px 70px 70px 100px 1fr 80px;
		}
	}
}

.activities-list {
	.results {
		.results-table {
			grid-template-columns: 36px 70px 1fr 80px;
		}
	}
}

.guest-book-list {
	.results {
		.results-table {
			grid-template-columns:
				36px // check
				80px // etourism_status
				72px // checked_out
				80px // id_no
				70px // zst
				180px // name_surname
				118px // dt_roj
				50px // sp
				80px // drzava
				210px // vrsta_dok
				96px // id_st_dok
				185px // cas_prihoda_range
				185px // cas_odhoda_range
				135px // cas_prihoda_range
				65px // ...
				65px // ...
				1fr // ...
			;
			
			&.warning {
				.results-table-col {
					color: #e06e00;
				}
			}
		}
	}
}

.monthly-guest-book-report-list {
	.results {
		.results-table {
			grid-template-columns: auto auto auto auto auto auto auto auto auto;
		}
	}
}

.guest-tourist-taxes-list {
	.by-countries-table {
		max-width: 700px;
		
		.results-table {
			grid-template-columns: auto 20% 20%;
		}
	}
	.by-tourist-tax-types-table {
		max-width: 700px;
		margin-bottom: 2rem;
		
		.results-table {
			grid-template-columns: auto 20% 20%;
		}
	}
}

.reports-items-and-services-list {
	max-width: 1100px;
	margin-bottom: 2rem;
	
	.results-table {
		grid-template-columns: auto auto auto auto auto auto auto;
	}
}

.activity-daily-logbook-list {
	.activity-daily-logbook-list-summary {
		/*display: grid;
		text-align: right;
		
		div {
			padding-left: 12px;
			padding-right: 12px;
			
			&.border-right {
				border-right: 1px solid #BBBBBB;
			}
			&.border-top {
				border-top: 1px solid #BBBBBB;
			}
			&.border-bottom {
				border-bottom: 1px solid #BBBBBB;
			}
		}*/
		.results {
			.results-table {
				grid-template-columns: auto;
				
				.results-table-header-col, .results-table-col {
					line-height: 16px;
				}
			}
		}
	}
	.results {
		.results-table {
			grid-template-columns:
				36px
				minmax(190px, 240px)
				130px
				70px
				130px
				70px
				130px
				70px
				minmax(60px, auto)
				minmax(60px, auto)
				50px
				minmax(60px, auto)
				minmax(60px, auto)
				50px
				minmax(60px, auto)
				minmax(60px, auto)
				50px
				56px
				56px
				56px
				56px
				minmax(60px, auto);
			
			&.hidden-dla {
				grid-template-columns:
					36px
					minmax(240px, 320px)
					130px
					70px
					130px
					70px
					130px
					70px
					repeat(9, 0px)
					56px
					56px
					56px
					56px
					minmax(60px, auto);
			}
			
			.results-table-header-col {}
			.results-table-header-col, .results-table-col {
				padding-left: 0.2rem;
				padding-right: 0.2rem;
				
				&.colored {
					background-color: #f3f5f7;
				}
				&.left-border {
					border-left: 2px solid #63849c;
					padding-left: 0.4rem;
				}
				&.extra-left-padding {
					padding-left: 0.6rem;
				}
			}
			.results-table-col {
				padding-top: 4px;
				padding-bottom: 4px;
				
				&.warning {
					background-color: #feebc8;
				}
				&.positive {
					background-color: #62D96B;
				}
				&.negative {
					background-color: #FF6E4A;
				}
				&.inactive {
					input.bp5-input { opacity: 0.5; }
				}
				&.negative {
					input.bp5-input {
						background: #D13913;
						color: #ffffff;
					}
				}
				&.positive {
					input.bp5-input {
						background: #29A634;
						color: #ffffff;
					}
				}
				
				.bp5-button.selector {
					opacity: 0.5;
					
					&.active {
						opacity: 1;
						background-color: #dddddd;
					}
				}
				
				.bp5-button.truncate {
					.bp5-button-text {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
				
				.bp5-button.bp5-align-left {
					justify-content: flex-start;
				}
				
				.tooltip-popover {
					display: block;
					
					span.bp5-popover-target {
						display: block;
					}
				}
			}
		}
	}
}

.loading-icon {
	animation-name: rotation-animation;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.mobile-activity-daily-logbook-list {
	max-width: 400px;
	.results {
		.results-table {
			grid-template-columns: auto auto auto;
			
			.results-table-header-col {}
			.results-table-header-col, .results-table-col {
				padding-left: 0.2rem;
				padding-right: 0.2rem;
				
				&.colored {
					background-color: #f3f5f7;
				}
				&.left-border {
					border-left: 2px solid #63849c;
					padding-left: 0.4rem;
				}
				&.extra-left-padding {
					padding-left: 0.6rem;
				}
			}
			.results-table-col {
				padding-top: 4px;
				padding-bottom: 4px;
				
				&.warning {
					background-color: #feebc8;
				}
				&.inactive {
					input.bp5-input { opacity: 0.5; }
				}
				&.negative {
					input.bp5-input {
						background: #D13913;
						color: #ffffff;
					}
				}
				&.positive {
					input.bp5-input {
						background: #29A634;
						color: #ffffff;
					}
				}
				
				.loading-icon {
					animation-name: rotation-animation;
					animation-duration: 1s;
					animation-iteration-count: infinite;
					animation-timing-function: linear;
				}
				
				.bp5-button.selector {
					opacity: 0.5;
					
					&.active {
						opacity: 1;
						background-color: #dddddd;
					}
				}
				
				.bp5-button.truncate {
					.bp5-button-text {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
				
				.bp5-button.bp5-align-left {
					justify-content: flex-start;
				}
				
				.tooltip-popover {
					display: block;
					
					span.bp5-popover-target {
						display: block;
					}
				}
			}
		}
	}
}

.dsc-statistics-list, .dsc-user-statistics-list {
	.results {
		.results-table {
			.results-table-header-col {}
			.results-table-header-col, .results-table-col {
				&.colored {
					background-color: #f3f5f7;
				}
				&.weekend {
					background-color: #95abbb;
				}
				&.left-border  { border-left:  2px solid #63849c; }
				&.right-border { border-right: 2px solid #63849c; }
				&.top-border   { border-top:   2px solid #63849c; }
			}
		}
	}
}

@keyframes rotation-animation {
	from { transform: rotate(0deg  ); }
	to   { transform: rotate(360deg); }
}

.sales-invoice-book-items-list {
	.results {
		.results-table {
			grid-template-columns: 36px 70px 108px 80px 1fr 210px 210px 96px 126px 100px 120px 120px;
		}
	}
}

.stock-inventory-list {
	.results {
		.results-table {
			max-height: calc(100vh - 210px);
			grid-template-columns: 70px 140px 140px 125px 125px 1fr 100px 140px 140px;
		}
	}
}

.stock-inventory-detailed-dialog {
	width: 97%;
	height: calc(100vh - 70px);
	
	.main-filters {
		.document-type {
			button {
				height: 26px;
			}
			
			.multiselect-button-wrapper {
				button {
					min-width: 240px;
					height: 27px;
				}
			}
		}
	}
	
	.stock-inventory-detailed-list {
		.results {
			.results-table {
				max-height: calc(100vh - 290px);
				grid-template-columns: 54px 116px 110px 135px 1fr 100px 124px 144px 124px 80px 60px 60px 60px 60px 80px 80px;
				
				.col-6 {
					text-overflow: ellipsis;
				}
			}
		}
	}
}

.reservation-list, .activity-reservation-list {
	.results {
		.results-table {
			
			&.status-new .results-table-col {
				background-color: scale-color(#e0e000, $lightness: 80%);
			}
			&.status-waiting-for-confirmation .results-table-col, &.status-offer-sent .results-table-col {
				background-color: scale-color(#e06e00, $lightness: 80%);
			}
			&.status-confirmed .results-table-col, &.status-advance-invoice-sent .results-table-col {
				background-color: scale-color(#2bb71c, $lightness: 80%);
			}
			&.status-closed .results-table-col {
				background-color: scale-color(#aaaaaa, $lightness: 80%);
			}
			&.status-reversed .results-table-col {
				background-color: scale-color(#ff0000, $lightness: 80%);
			}
			&.status-no-show .results-table-col {
				background-color: scale-color(#ff0000, $lightness: 80%);
			}
			&.status-not-for-rent .results-table-col {
				background-color: scale-color(#ff0000, $lightness: 80%);
			}
			&.status-deleted .results-table-col {
				background-color: scale-color(#ff0000, $lightness: 80%);
			}
		}
	}
	
	.one-line-minimum {
		min-height: 28px; /* same as line height */
	}
	
	.reservation-list-pattern-diagonal-stripes {
		width: 100%;
		height: 100%;
		
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18'><defs><pattern id='pattern-diagonal-stripes' viewBox='0,0,40,40' width='18' height='18' patternUnits='userSpaceOnUse'><g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.25'><g fill='%23000000'><polygon points='0 40 40 0 20 0 0 20'></polygon><polygon points='40 40 40 20 20 40'></polygon></g></g></pattern></defs><rect x='0' y='0' width='18' height='18' fill='url(%23pattern-diagonal-stripes)' opacity='1' /></svg>");
		background-repeat: repeat;
	}
	.reservation-list-pattern-polka-dots {
		width: 100%;
		height: 100%;
		
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18'><defs><pattern id='pattern-polka-dots' viewBox='0,0,20,20' width='18' height='18' patternUnits='userSpaceOnUse'><g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.25'><g fill='%23000000'><circle cx='3' cy='3' r='3'></circle><circle cx='13' cy='13' r='3'></circle></g></g></pattern></defs><rect x='0' y='0' width='18' height='18' fill='url(%23pattern-polka-dots)' opacity='1' /></svg>");
		background-repeat: repeat;
	}
}
.reservation-list {
	.results {
		.results-table {
			position: relative;
			
			grid-template-columns:
				30px /* actions */
				40px /* pattern */
				180px /* accommodation */
				100px /* status */
				108px /* internal code */
				1fr /* guests */
				150px /* country */
				210px /* identification type */
				100px /* identification number */
				190px /* check in */
				190px /* check out */
				60px /* guest count */
				80px /* guests_checked_in */
				;
			
			.custom-status {
				display: none;
				position: absolute;
				left: 1px;
				top: 0px;
			}
			&.custom-status-1 .custom-status {
				display: block;
				
				path { fill: #ff0000; }
			}
			&.custom-status-2 .custom-status {
				display: block;
				
				path { fill: #0000ff; }
			}
			&.custom-status-3 .custom-status {
				display: block;
				
				path { fill: #00ffff; }
			}
			&.custom-status-4 .custom-status {
				display: block;
				
				path { fill: #ff00ff; }
			}
			&.custom-status-5 .custom-status {
				display: block;
				
				path { fill: #0000ff; }
			}
			&.custom-status-6 .custom-status {
				display: block;
				
				path { fill: #00ffff; }
			}
			&.custom-status-7 .custom-status {
				display: block;
				
				path { fill: #ff00ff; }
			}
		}
	}
	
	.reservation-list-guests {
		line-height: 2.5;
	}
	.reservation-list-guests-table {
		display: grid;
		grid-gap: 0px;
		grid-auto-rows: minmax(28px, auto);
		grid-template-columns:
			50px /* e-Tourism icon */
			1fr /* name */
			auto /* invoices */
			;
	}
}

.activity-reservation-list {
	.results {
		.results-table {
			position: relative;
			
			grid-template-columns:
				30px  /* actions          */
				40px  /* pattern          */
				190px /* reservation date */
				160px /* activity         */
				100px /* status           */
				108px /* internal code    */
				260px /* customer         */
				150px /* country          */
				100px /* channel          */
				60px  /* guest count      */
				72px  /* user             */
				1fr   /* note             */
				;
			
			.custom-status {
				display: none;
				position: absolute;
				left: 1px;
				top: 0px;
			}
			&.custom-status-1 .custom-status {
				display: block;
				
				path { fill: #ff0000; }
			}
			&.custom-status-2 .custom-status {
				display: block;
				
				path { fill: #0000ff; }
			}
			&.custom-status-3 .custom-status {
				display: block;
				
				path { fill: #00ffff; }
			}
			&.custom-status-4 .custom-status {
				display: block;
				
				path { fill: #ff00ff; }
			}
			&.custom-status-5 .custom-status {
				display: block;
				
				path { fill: #0000ff; }
			}
			&.custom-status-6 .custom-status {
				display: block;
				
				path { fill: #00ffff; }
			}
			&.custom-status-7 .custom-status {
				display: block;
				
				path { fill: #ff00ff; }
			}
		}
	}
}

.document-search-reservations-list {
	.results {
		.results-table {
			grid-template-columns:
				180px /* accommodation */
				100px /* status        */
				108px /* internal code */
				1fr   /* guests        */
				70px  /* country       */
				190px /* check in      */
				190px /* check out     */
				auto  /* select button */
				;
		}
	}
}

.document-search-delivery-note-list {
	.results {
		.results-table {
			grid-template-columns:
				180px /* document number */
				108px /* internal code   */
				1fr   /* customer name   */
				190px /* document date   */
				190px /* type            */
				auto  /* select button   */
				;
		}
	}
}

.general-settings-list {
	.results {
		.results-table {
			grid-template-columns:
				110px /* actions */
				240px /* key     */
				1fr   /* value   */
			;
		}
		
		&.documents {
			.results-table {
				grid-template-columns:
					110px /* actions */
					240px /* key     */
					 60px /* sl      */
					 60px /* en      */
					 60px /* de      */
					 60px /* pos     */
				;
			}
		}
	}
}

.health-log-list {
	.results {
		.results-table {
			grid-template-columns: repeat(7, 1fr);
		}
	}
}

.add-journal-dialog-sums-list {
	.results {
		.results-table {
			grid-template-columns: auto 162px 162px;
		}
	}
}
.add-journal-dialog-sums-field {
	width: 157px;
	
	.add-journal-dialog-sums-field-value {
		padding: 0px 9px;
		background-color: rgba(206, 217, 224, 0.5);
		color: rgba(92, 112, 128, 0.8);
		line-height: 27px;
	}
}

.add-journal-dialog-documents-list {
	.results {
		.results-table {
			grid-template-columns: auto auto auto auto auto auto auto auto;
		}
	}
}

.add-monthly-guest-book-report-dialog-documents-list {
	.results {
		.results-table {
			grid-template-columns: 33px 1fr 90px 1fr 90px 90px 90px;
			
			input {
				width: 100%;
			}
		}
	}
}

.journal-list {
	.results {
		.results-table {
			max-height: calc(100vh - 210px);
		}
	}
	
	.results .results-table, .results-sum {
		grid-template-columns: 36px 80px 120px 220px 180px auto 180px 180px 180px 180px 180px;
	}
	
	.results-sum {
		display: grid;
		grid-gap: 0px;
		padding-right: 26px;
	}
}

.cash-register-documents-list {
	.results {
		.results-table {
			max-height: calc(100vh - 210px);
			grid-template-columns: 36px 80px 120px 120px 120px 220px 80px auto 160px 140px 140px 120px;
		}
	}
}

.stock-acquisition-document-list {
	.results {
		.results-table {
			max-height: calc(100vh - 226px);
			grid-template-columns: 40px 80px 110px 80px 1fr 180px 180px 150px 150px 120px 100px 100px;
		}
	}
}

.centered-numeric-input {
	.bp5-input-group {
		.bp5-input {
			text-align: center;
		}
	}
}

.dialog--note-popup-data {
	textarea.bp5-input.bp5-large {
		min-height: 6rem;
	}
}

.document-search-dialog, .prepayment-document-search-dialog {
	position: absolute;
	min-width: 80%;
	height: 80%;
	
	padding-bottom: 0px;
	
	.bp5-dialog-body {
		margin: 0px;
		height: calc(100% - 50px);
	}
	
	.left-side {
		width: 240px;
		border-radius: 0px 0px 0px 6px;
	}
}

.edit-invoice-dialog, .add-edit-stock-acquisition-document-dialog, .add-edit-delivery-note-dialog {
	position: absolute;
	min-width: 90%;
	height: 96%;
	padding-bottom: 0px;
	margin: 0px;
	
	.bp5-dialog-body {
		display: flex;
	}
	
	.invoice-header {
		display: grid;
		grid-gap: 0.5rem;
		grid-template-columns: 240px 120px 120px auto 240px 1fr 480px;
		
		.bp5-form-group {
			margin-bottom: 0.5rem;
		}
		
		.invoice-number {
			grid-column: 1 / 8;
			grid-row: 1;
		}
		.customer {
			grid-column: 1;
			grid-row: 2 / 4;
			
			.customer-info {
				min-height: 73px;
				padding-top: 11px;
				
				.customer-info-inner {
					padding-right: 26px;
					padding-top: 6px;
				}
			}
		}
		.vat-type {
			grid-column: 1;
			grid-row: 4;
		}
		/*.customer-info {
			grid-column: 1;
			grid-row: 3 / 5;
		}*/
		.invoice-date {
			grid-column: 2;
			grid-row: 2;
			
			&.wide {
				grid-column: 2 / 4;
			}
		}
		.payment-date-paid, .credit-note-type {
			grid-column: 4;
			grid-row: 2;
		}
		.payment-date {
			grid-column: 3;
			grid-row: 2;
		}
		.service-date {
			grid-column: 2 / 4;
			grid-row: 3;
			
			.bp5-form-content {
				&> .bp5-popover-wrapper {
					&> span.bp5-popover-target {
						display: block;
						
						.from-until {
							flex: 1;
							
							&.bp5-input-group .bp5-input {
								width: 100%;
							}
						}
					}
				}
			}
		}
		.reference {
			grid-column: 4;
			grid-row: 3;
		}
		.reservation {
			grid-column: 4;
			grid-row: 4;
		}
		.payment-type {
			grid-column: 2 / 4;
			grid-row: 4;
		}
		.split-payment-form {
			grid-column: 5;
			grid-row: 2 / 5;
			
			.split-payment-form-table {
				display: grid;
				grid-gap: 0.7rem;
				grid-template-columns: auto 1fr;
				
				.col-1 {
					grid-column: 1;
				}
				.col-2 {
					grid-column: 2;
					text-align: right;
				}
			}
		}
		.documents {
			grid-column: 7;
			grid-row: 2 / 5;
			
			max-height: 170px;
			/*overflow-y: auto;*/
			
			.bp5-tab {
				line-height: normal;
				
				&[aria-selected="true"] {
					
				}
			}
			
			.bp5-tab-indicator-wrapper {
				display: none;
			}
			
			.bp5-tab-panel {
				margin-top: 0px;
			}
		}
	}
	
	.items-list-wrapper {
		position: relative;
		margin-top: 18px;
		
		.items-list-scroll-container {
			position: absolute;
			left: 0px;
			right: 0px;
			top: 0px;
			bottom: 0px;
			overflow-y: auto;
			
			.items-list {
				border-left: 1px solid #ccd4d8;
				border-top: 1px solid #ccd4d8;
				
				.items-table {
					display: grid;
					grid-gap: 0px;
					grid-auto-rows: minmax(28px, auto);
					grid-template-columns:
						auto /* action */
						1fr /* title */
						120px /* description */
						auto /* count */
						auto /* price per item */
						auto /* discount */
						auto /* price without vat */
						auto /* vat */
						auto /* price with vat */
						auto /* price list */
					;
					
					&.credit-note {
						grid-template-columns:
							auto /* action */
							1fr /* title */
							120px /* description */
							auto /* allowed discount */
							auto /* count */
							auto /* price per item */
							auto /* discount */
							auto /* price without vat */
							auto /* vat */
							auto /* price with vat */
							auto /* price list */
						;
						
						&.read-only {
							grid-template-columns:
								1fr /* title */
								120px /* description */
								auto /* allowed discount */
								auto /* count */
								auto /* price per item */
								auto /* discount */
								auto /* price without vat */
								auto /* vat */
								auto /* price with vat */
								auto /* price list */
							;
						}
					}
					
					.items-table-header-col, .items-table-col {
						padding-left: 0.5rem;
						padding-right: 0.5rem;
						border-right: 1px solid #ccd4d8;
						border-bottom: 1px solid #ccd4d8;
						white-space: nowrap;
						overflow: hidden;
						font-size: 0.7rem;
						font-weight: 700;
						line-height: 28px;
						
						.bp5-button {
							font-size: 0.7rem;
						}
						
						.bp5-control {
							margin: 0px;
						}
						
						input {
							width: 80px;
							padding-left: 0.5rem;
							padding-right: 0.5rem;
							font-weight: 500;
							
							&:focus {
								background-color: rgba(51, 136, 195, 0.12);
							}
						}
						
						input[type="number"] {
							appearance: textfield;
							-webkit-appearance: textfield;
							font-weight: 700;
							
							&::-webkit-inner-spin-button, &::-webkit-outer-spin-button { 
								-webkit-appearance: none; 
								margin: 0; 
							}
						}
						
						&.service-suggest {
							input {
								width: 100%;
								border: 0px;
								box-shadow: none;
							}
						}
						
						&.tight {
							padding-left: 0px;
							padding-right: 0px;
						}
					}
					
					.items-table-header-col {
						position: sticky;
						top: 0px;
						
						grid-row: 1;
						font-weight: 600;
						background-color: #e0e8ec;
						
						line-height: 1.6;
						padding-top: 0.5rem;
						padding-bottom: 0.5rem;
						
						z-index: 100;
					}
					
					.items-table-col {
						background-color: #ffffff;
						
						-webkit-user-select: text;
						-moz-user-select:    text;
						-ms-user-select:     text;
						user-select:         text;
						
						&.description {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							padding-left: 4px;
							padding-right: 4px;
							
							transition: background-color 300ms ease;
							cursor: pointer;
							
							&:hover {
								background-color: #eeeeee;
							}
						}
					}
				}
			}
		}
	}
	
	.items-summary {
		display: grid;
		grid-gap: 0px;
		grid-auto-rows: minmax(28px, auto);
		grid-template-columns: auto auto 1fr auto 186px;
		
		margin: -18px;
		margin-top: 0px;
		margin-bottom: 0px;
		padding: 18px;
		padding-bottom: 6px;
		background-color: rgba(57, 75, 89, 0.1);
		
		&:last-child {
			margin-bottom: -18px;
		}
		
		.col-1 { grid-column: 1; padding-right: 1rem; }
		.col-2 { grid-column: 2; }
		.col-3 { grid-column: 3; }
		.col-4 { grid-column: 4; text-align: right; }
		.col-5 { grid-column: 5; padding-left: 1rem; }
	}
	
	.advance-invoice-list {
		border-left: 1px solid #ccd4d8;
		border-top: 1px solid #ccd4d8;
		
		&.without-add-and-remove {
			.advance-invoice-list-table {
				grid-template-columns: 80px 90px 1fr 1fr;
			}
		}
	}
	
	.advance-invoice-list-table {
		position: relative;
		display: grid;
		grid-gap: 0px;
		grid-auto-rows: minmax(28px, auto);
		grid-template-columns: 38px 80px 90px 1fr 1fr;
		
		&.floating-header {
			position: sticky;
			top: 0px;
		}
		
		.advance-invoice-list-table-header-col, .advance-invoice-list-table-col {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			border-right: 1px solid #ccd4d8;
			border-bottom: 1px solid #ccd4d8;
			white-space: nowrap;
			overflow: hidden;
			font-size: 0.7rem;
			line-height: 28px;
			
			.bp5-button {
				font-size: 0.7rem;
			}
			
			.bp5-control {
				margin: 0px;
			}
		}
		
		.advance-invoice-list-table-header-col {
			grid-row: 1;
			font-weight: 600;
			background-color: #e0e8ec;
		}
		
		.advance-invoice-list-table-col {
			background-color: #ffffff;
		}
	}
}

.add-edit-stock-acquisition-document-dialog {
	min-width: 98%;
	
	.invoice-header {
		.document-number {
			grid-column: 1 / 8;
			grid-row: 1;
		}
		.document-date {
			grid-column: 2;
			grid-row: 2;
		}
		.book-date {
			grid-column: 3;
			grid-row: 2;
		}
		.delivery-note-date {
			grid-column: 2;
			grid-row: 3;
		}
		.delivery-note-number {
			grid-column: 3;
			grid-row: 3;
		}
		.type {
			grid-column: 4;
			grid-row: 3;
		}
		.warehouse {
			grid-column: 4;
			grid-row: 2;
		}
		.stock-from-warehouse {
			grid-column: 5;
			grid-row: 2;
		}
	}
	.items-list-wrapper {
		.items-list-scroll-container {
			.items-list {
				.items-table {
					grid-template-columns:
						60px  /* action                          */
						1fr   /* title                           */
						60px  /* quantity                        */
						90px  /* price without VAT               */
						140px /* acquisition VAT                 */
						90px  /* price with VAT                  */
						70px  /* discount                        */
						90px  /* price with discount without VAT */
						90px  /* price with discount with VAT    */
						90px  /* total price without VAT         */
						90px  /* total price with VAT            */
						140px /* VAT                             */
						90px  /* sell price with VAT             */
					;
					
					input[type="text"] {
						width: 100%;
					}
				}
			}
		}
	}
	.items-summary {
		grid-template-columns: auto 0px 60px 1fr auto 120px 32px auto 120px;
	}
}

.add-edit-delivery-note-dialog {
	min-width: 98%;
	
	.invoice-header {
		.document-number {
			grid-column: 1 / 8;
			grid-row: 1;
		}
		.document-date {
			grid-column: 2;
			grid-row: 2;
		}
		.issue-date {
			grid-column: 3;
			grid-row: 2;
		}
		.order-date {
			grid-column: 2;
			grid-row: 3;
		}
		.order-number {
			grid-column: 3;
			grid-row: 3;
		}
		.type {
			grid-column: 4;
			grid-row: 3;
		}
		.warehouse {
			grid-column: 4;
			grid-row: 2;
		}
	}
	.items-list-wrapper {
		.items-list-scroll-container {
			.items-list {
				.items-table {
					grid-template-columns:
						60px  /* action                          */
						1fr   /* title                           */
						60px  /* quantity                        */
						90px  /* price without VAT               */
						90px  /* price with VAT                  */
						70px  /* discount                        */
						90px  /* price with discount without VAT */
						90px  /* price with discount with VAT    */
						90px  /* total price without VAT         */
						90px  /* total price with VAT            */
						160px /* VAT                             */
						100px /* current stock                   */
					;
					
					input[type="text"] {
						width: 100%;
					}
				}
			}
		}
	}
	.items-summary {
		grid-template-columns: auto 0px 60px 1fr auto 120px 32px auto 120px;
	}
}

.add-edit-business-premise-dialog {
	min-width: 620px;
}

.item-label-print-dialog {
	min-width: 1200px;
	max-width: 95%;
	
	.list {
		grid-template-columns: 1fr 60px 60px 1fr 60px 60px 1fr 60px 60px;
		align-items: center;
	}
}

.add-edit-item-dialog {
	width: 1100px;
	max-width: 95%;
	
	.form-fields {
		display: grid;
		grid-gap: 0.5rem;
		grid-template-columns: 1fr 0.6fr 1.3fr 1.2fr;
		
		.bp5-form-group {
			margin-bottom: 0.5rem;
		}
		
		.internal-code {
			grid-column: 1;
			grid-row: 1;
		}
		.title {
			grid-column: 1 / 3;
			grid-row: 2;
		}
		.description {
			grid-column: 1 / 4;
			grid-row: 3;
		}
		.ean {
			grid-column: 2;
			grid-row: 1;
		}
		.id-measuring-unit {
			grid-column: 3;
			grid-row: 2;
		}
		.type {
			grid-column: 3;
			grid-row: 1;
		}
		.id-tax-rate {
			grid-column: 3;
			grid-row: 2;
		}
		.active {
			grid-column: 4;
			grid-row: 1;
		}
		.allow_changing_price {
			grid-column: 4;
			grid-row: 2;
		}
		.show-description-on-documents {
			grid-column: 4;
			grid-row: 3;
		}
		
		.tabs {
			min-height: 80px;
			grid-column: 1 / 5;
			grid-row: 4;
			
			padding: 16px;
			padding-top: 8px;
			background-color: #ffffff;
			border-radius: 3px;
			box-shadow: 0 0 0 0 rgb(19 124 189 / 0%), 0 0 0 0 rgb(19 124 189 / 0%), inset 0 0 0 1px rgb(16 22 26 / 15%), inset 0 1px 1px rgb(16 22 26 / 20%);
			
			.bp5-tab-list {
				border-bottom: 1px solid #106ba3;
			}
			
			.bp5-tab-panel {
				min-height: 250px;
			}
			
			.price-list {
				padding-right: 32px;
				
				.price-list-items {
					display: grid;
					column-gap: 0.8rem;
					row-gap: 0.2rem;
					grid-template-columns: auto auto auto auto auto auto;
				}
			}
		}
		/*.price-list-edit {
			min-height: 80px;
			grid-column: 3 / 5;
			grid-row: 4;
		}*/
	}
}

.wait-fiscal-confirmation-dialog {
	min-height: 200px;
	
	.wait-fiscal-confirmation-dialog-body-wrapper {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
	}
	.wait-fiscal-confirmation-dialog-body {
		display: grid;
		grid-column-gap: 32px;
		grid-row-gap: 8px;
		grid-template-columns: auto 1fr;
	}
}

.add-journal-dialog {
	width: 1000px;
	align-self: stretch;
}

.add-cash-register-document-dialog {
	width: 1000px;
	min-height: 400px;
	
	.base-list.add-cash-register-document-dialog-payment-type-list .results .results-table {
		grid-template-columns: auto auto 90px 1fr;
		
		.results-table-col {
			input {
				width: 100%;
			}
		}
	}
}

.add-monthly-guest-book-report-dialog {
	width: 1200px;
	height: 500px;
}

.invoice-fiscal-verification-history-dialog, .business-premise-fiscal-registration-history-dialog {
	width: 880px;
	height: 600px;
	
	.fiscal-verification-items {
		background-color: #ffffff;
		margin-right: 0.5rem;
		
		.fiscal-verification-item {
			padding: 16px;
			background-color: #ffffff;
			transition: background-color 300ms ease;
			cursor: pointer;
			border-bottom: 1px solid #cccccc;
			
			&:hover {
				background-color: #eeeeee;
			}
			
			&.selected {
				background-color: #394b59;
				color: #ffffff;
			}
		}
	}
	
	.fiscal-verification-messages {
		padding: 8px;
		background-color: #ffffff;
		
		pre {
			user-select: text;
		}
	}
}

.bp5-suggest-popover .bp5-menu {
	width: 420px;
	max-height: 580px;
}
.bp5-menu-item {
	flex-direction: column;
	padding: 8px;
}
	.bp5-menu-item-horizontal {
		flex-direction: row;
	}
	.bp5-menu-item .bp5-menu-item-label {
		color: #9a9a9a;
	}

.bp5-form-group.validation-failed {
	color: #ff2222;
}

.reports-activity-reservations-list {
	display: grid;
	/*grid-template-columns: 10fr 2fr 320px 3fr 4fr 2fr 320px;*/
	grid-template-columns: 10fr 2fr 320px 3fr 4fr 320px 320px 2fr;
	grid-gap: 0px;
	grid-auto-rows: minmax(38px, auto);
	
	.col {
		position: relative;
		padding: 4px;
		
		&.status-new {
			background-color: #e0e000;
		}
		&.status-waiting-for-confirmation, &.status-offer-sent {
			background-color: #e06e00;
			color: #ffffff;
		}
		&.status-confirmed, &.status-advance-invoice-sent {
			background-color: #2bb71c;
			color: #ffffff;
		}
		&.status-advance-invoice-sent { }
		&.status-closed {
			background-color: #aaaaaa;
			color: #ffffff;
		}
		&.status-dragging {
			background-color: rgba(57, 75, 89, 0.8);
			color: #ffffff;
		}
		&.status-deleting {
			background-color: #ff0000;
			color: #ffffff;
		}
		&.status-reversed {
			background-color: #ff0000;
			color: #ffffff;
		}
		&.status-no-show {
			background-color: #ff0000;
			color: #ffffff;
		}
		&.status-invalid {
			background-color: #cc0000;
			color: #ffffff;
		}
		&.status-deleted {
			background-color: scale-color(#ff0000, $lightness: 80%);
		}
		&.reservation-list-pattern-diagonal-stripes {
			width: 100%;
			height: 100%;
			
			background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18'><defs><pattern id='pattern-diagonal-stripes' viewBox='0,0,40,40' width='18' height='18' patternUnits='userSpaceOnUse'><g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.08'><g fill='%23000000'><polygon points='0 40 40 0 20 0 0 20'></polygon><polygon points='40 40 40 20 20 40'></polygon></g></g></pattern></defs><rect x='0' y='0' width='18' height='18' fill='url(%23pattern-diagonal-stripes)' opacity='1' /></svg>");
			background-repeat: repeat;
		}
		&.reservation-list-pattern-polka-dots {
			width: 100%;
			height: 100%;
			
			background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18'><defs><pattern id='pattern-polka-dots' viewBox='0,0,20,20' width='18' height='18' patternUnits='userSpaceOnUse'><g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.08'><g fill='%23000000'><circle cx='3' cy='3' r='3'></circle><circle cx='13' cy='13' r='3'></circle></g></g></pattern></defs><rect x='0' y='0' width='18' height='18' fill='url(%23pattern-polka-dots)' opacity='1' /></svg>");
			background-repeat: repeat;
		}
		
		.custom-status {
			display: none;
			position: absolute;
			left: 1px;
			top: 0px;
		}
		&.custom-status-1 .custom-status {
			display: block;
			
			path { fill: #ff0000; }
		}
		&.custom-status-2 .custom-status {
			display: block;
			
			path { fill: #0000ff; }
		}
		&.custom-status-3 .custom-status {
			display: block;
			
			path { fill: #00ffff; }
		}
		&.custom-status-4 .custom-status {
			display: block;
			
			path { fill: #ff00ff; }
		}
		&.custom-status-5 .custom-status {
			display: block;
			
			path { fill: #0000ff; }
		}
		&.custom-status-6 .custom-status {
			display: block;
			
			path { fill: #00ffff; }
		}
		&.custom-status-7 .custom-status {
			display: block;
			
			path { fill: #ff00ff; }
		}
		
		&.reservation {
			padding-top: 11px;
			
			&.col-1 {
				padding-top: 8px;
				
				.customer-name {
					padding-top: 3px;
				}
			}
		}
		
		&.time-label, &.activity-label {
			padding-left: 8px;
		}
	}
	
	.col-1 { grid-column: 1; }
	.col-2 { grid-column: 2; }
	.col-3 { grid-column: 3; }
	.col-4 { grid-column: 4; }
	.col-5 { grid-column: 5; }
	.col-6 { grid-column: 6; }
	.col-7 { grid-column: 7; }
	.col-8 { grid-column: 8; }
}

.customer-delivery-address-picker-dialog {
	height: 600px;
	max-height: 90vh;
}

.bp5-datepicker-timepicker-wrapper {
	margin-top: 1rem;
}

//@tailwind utilities;
